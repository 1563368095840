<div class="tab-content">
  <div class="tab-pane fade show active">
    <div class="card shadow-none border-0">
      <div class="card-header d-flex flex-wrap align-items-center">
        <!-- filter -->
        <div class="mr-auto">
          <div class="d-flex align-items-center">
            Search:
            <div class="input-group search-group ml-1 w-230">
              <input
                [formControl]="searchText"
                type="text"
                class="form-control"
                placeholder="Search for a User" />

              <div class="input-group-append">
                <span class="input-group-text">
                  <img src="assets/icons/search.svg" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="m-r-10" ngbDropdown>
          <button type="button" class="btn btn-secondary" ngbDropdownToggle>
            Filter
            <img src="assets/icons/arrow-down-primary.svg" />
          </button>

          <div class="dropdown-menu-right filter-dropdown" ngbDropdownMenu>
            <div class="log-arrow-up"></div>

            <ul class="list-unstyled m-0">
              <li *ngFor="let filter of filters | keyvalue">
                <div class="form-row align-items-center">
                  <label class="control control-outline control-primary control--checkbox">
                    {{ filter.value.name }}

                    <input type="checkbox" (change)="updateFilterSelection(filter.key, $event.target.checked)" />

                    <div class="control__indicator"></div>
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="m-r-10">
          <button class="btn btn-secondary" (click)="handleOnApply()">
            Apply
          </button>
        </div>

        <div class="m-r-0" *ngIf="!readOnly">
          <button type="button" class="btn btn-primary" (click)="openModel(null)">
            Add New User
          </button>
        </div>

        <ng-container [formGroup]="filterForm">
          <div class="d-flex filter-responsive flex-wrap justify-content-start align-items-center my-2">
            <div *ngIf="filters.group.enabled" class="d-flex align-items-center mr-4">
              User Group:

              <ng-select
                [items]="userGroupFilterOptions"
                [formControl]="group"
                bindLabel="name"
                bindValue="id"
                placeholder="Select:"
                class="expanding-dropdown-panel"
                [multiple]="true"
                appendTo="body"
              >
              </ng-select>
            </div>

            <div *ngIf="filters.status.enabled" class="d-flex align-items-center">
              Status:

              <ng-select
                [formControl]="status"
                bindLabel="name"
                bindValue="id"
                [items]="userStatusFilterOptions"
                placeholder="Select:"
                class="expanding-dropdown-panel"
                [multiple]="true"
                appendTo="body"
              >
              </ng-select>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="card-body p-0">
        <div class="table-responsive-md">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">
                  First Name
                </th>
                <th scope="col">
                  Last Name
                </th>
                <th scope="col">
                  <app-sortable-label name="email" [current]="sort.field" [direction]="sort.direction" (change)="handleOnSort($event)">
                    Email
                  </app-sortable-label>
                </th>
                <th scope="col">
                  User Groups
                </th>
                <th scope="col" class="w-110">
                  <app-sortable-label name="status" [current]="sort.field" [direction]="sort.direction" (change)="handleOnSort($event)">
                    Status
                  </app-sortable-label>
                </th>
                <th scope="col" *ngIf="!readOnly">
                  <span class="v-none">act</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let user of users; let index = index">
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <ng-select
                    [items]="userGroupOptions"
                    [closeOnSelect]="true"
                    bindLabel="name"
                    bindValue="id"
                    [ngModel]="getUserGroup(user)"
                    (change)="updateUserGroup($event, user.id)"
                    [readonly]="user.is_primary || readOnly"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                </td>

                <td>
                  <ng-select
                    bindValue="id"
                    bindLabel="name"
                    [(ngModel)]="user.status"
                    [items]="selectStatus"
                    placeholder="Status"
                    (change)="updateUserStatus($event, user.id)"
                    [readonly]="user.is_primary || readOnly"
                    class="expanding-dropdown-panel"
                    appendTo="body"
                  >
                  </ng-select>
                </td>

                <td *ngIf="!readOnly">
                  <a>
                    <img (click)="openModel(user)" src="assets/icons/pen.svg" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template>
  <app-user-management-settings-model></app-user-management-settings-model>
</ng-template>
